<template>
  <div>
    <CCard>
      <CCardBody>
        <br />
        <h1 class="text-center font-weight-normal">
          ระบบสมาชิก
        </h1>
        <hr />
        <br />
        <CRow class="d-flex justify-content-center">
          <CCol md="3" class="text-center">
            <router-link :to="'/loyalty-point'">
              <CJumbotron color="white" border-color="light">
                <CImg
                  src="../../../img/reward/loyalty-point.png"
                  width="100px"
                />
                <br />
                <br />
                <h4 class="text-black">คะแนนสะสม</h4>
              </CJumbotron>
            </router-link>
          </CCol>

          <CCol md="3" class="text-center">
            <router-link :to="'/rewardcard'">
              <CJumbotron color="white" border-color="light">
                <CImg src="../../../img/reward/reward-card.png" width="100px" />
                <br />
                <br />
                <h4 class="text-black">บัตรสะสมแต้ม</h4>
              </CJumbotron>
            </router-link>
          </CCol>
          <CCol md="3" class="text-center">
            <router-link :to="'/store-credit'">
              <CJumbotron color="white" border-color="light">
                <CImg
                  src="../../../img/reward/store-credit.png"
                  width="100px"
                />
                <br />
                <CBadge color="success font-weight-normal">coming soon</CBadge>
                <br />
                <h4 class="text-black">เครดิตสะสม</h4>
              </CJumbotron>
            </router-link>
          </CCol>
          <CCol md="3" class="text-center">
            <router-link :to="'/prepaid-items'">
              <CJumbotron color="white" border-color="light">
                <CImg
                  src="../../../img/reward/prepaid-item.png"
                  width="100px"
                />
                <br />
                <CBadge color="success">coming soon</CBadge>
                <br />
                <h4 class="text-black">ดีลสินค้า</h4>
              </CJumbotron>
            </router-link>
          </CCol>
          <CCol md="3" class="text-center">
            <router-link :to="'/referral'">
              <CJumbotron color="white" border-color="light">
                <CImg src="../../../img/reward/referral.png" width="100px" />
                <br />
                <CBadge color="success">coming soon</CBadge>
                <br />
                <h4 class="text-black">แนะนำเพื่อน</h4>
              </CJumbotron>
            </router-link>
          </CCol>

          <CCol md="3" class="text-center">
            <router-link :to="'/loyalty-point/history'">
              <CJumbotron color="white" border-color="light">
                <CImg src="../../../img/reward/transaction.png" width="100px" />
                <br />
                <br />
                <h4 class="text-black">ประวัติการทำรายการ</h4>
              </CJumbotron>
            </router-link>
          </CCol>
          <CCol md="3" class="text-center">
            <router-link :to="'/crm/settings'">
              <CJumbotron color="white" border-color="light">
                <CImg src="../../../img/reward/setting.png" width="100px" />
                <br />
                <br />
                <h4 class="text-black">ตั้งค่า</h4>
              </CJumbotron>
            </router-link>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['shops', 'users']),
  },
}
</script>
